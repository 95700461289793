import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const Success = () => (
  <Layout>
    <h2>Gracias</h2>
    <p>Su mensaje ha sido enviado</p>
    <Link to="/es">Volver al inicio</Link>
  </Layout>
)

export default Success
